<template>
  <div class="row">
    <div class="col-12 d-flex flex-column justify-content-center">
      <h1 class="text-center text-danger">No hemos encontrado nada en ésta ruta</h1>
      <div class="text-center">
        <router-link :to="{ name: 'Inicio' }" class="btn btn-lg btn-wide btn-info">
          Volver al inicio
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NotFound",
  props: {
    iconoTitulo: String,
    titleCard: String,
    toCreateAction: String,
    toCreateText: String,
  },
};
</script>